<template>
  <div class="inpatient_container">
    <el-row class="inpatient_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="file_row">
    </div>
    <el-button type="primary" @click.native="print" class="print">打印</el-button>
    <div class="inpatient_main">
      <el-table :data="tableData" v-loading="loading"   height="550" style="width: 100% ;text-align: center" :header-cell-style="{'text-align': 'center'}" :cell-style="{'text-align': 'center'}" ref="multipleTable"
        highlight-current-row :row-class-name="tableRowClassName" @row-click="toggleSelection(index)">
        <el-table-column label="项目名称" prop="patName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类别" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemTotalFee }}</span>
          </template>
        </el-table-column>
        <el-table-column label="单价（元）/数量" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemPrice }}/{{ scope.row.itemNumber }}{{ scope.row.itemUnit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="总金额" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.itemPrice }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div>
        <img :src="'data:image/png;base64,'+imgurl"
             alt="">
      </div> -->
    </div>
    <div class="line"></div>

    <el-button type="primary" @click.native="back" class="detailBack">返回</el-button>
    <!-- <el-button type="primary"
               @click.native="back"
               class="back">返回</el-button> -->
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from "@/baseAPI";
export default {
  components: {
    Foo, Count
  },
  data () {

    return {
      value: '',
      show: false,
      name: '住院费用详情',
      tableData: [
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "Ⅰ级护理",
              "itemType": "护理费",
              "itemUnit": "日",
              "itemTotalFee": "30.2",
              "itemPrice": "30.2"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "静脉采血",
              "itemType": "注射费",
              "itemUnit": "次",
              "itemTotalFee": "3.6",
              "itemPrice": "3.6"
            },
            {
              "itemSpec": "30ml  1.2mm*38mmTWSB纸塑",
              "itemNumber": "1",
              "itemName": "一次性使用无菌注射器 带针",
              "itemType": "一次性材料",
              "itemUnit": "支",
              "itemTotalFee": "0.81",
              "itemPrice": "0.814"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "妇科常规检查",
              "itemType": "治疗费",
              "itemUnit": "次",
              "itemTotalFee": "8.9",
              "itemPrice": "8.9"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "后穹窿穿刺术",
              "itemType": "治疗费",
              "itemUnit": "次",
              "itemTotalFee": "68.1",
              "itemPrice": "68.1"
            },
            {
              "itemSpec": "/支",
              "itemNumber": "1",
              "itemName": "无抗真空管(干管)",
              "itemType": "一次性材料",
              "itemUnit": "支",
              "itemTotalFee": "0.85",
              "itemPrice": "0.85"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "乙型肝炎表面抗原测定(HBsAg)-酶标法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "7.3",
              "itemPrice": "7.3"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "乙型肝炎表面抗体测定(Anti-HBs)-酶标法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "7.3",
              "itemPrice": "7.3"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "乙型肝炎e抗原测定(HBeAg)-酶标法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "7.3",
              "itemPrice": "7.3"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "乙型肝炎e抗体测定(Anti-HBe)-酶标法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "7.3",
              "itemPrice": "7.3"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "乙型肝炎核心抗体测定(Anti-HBc)-酶标法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "7.3",
              "itemPrice": "7.3"
            },
            {
              "itemSpec": "/支",
              "itemNumber": "1",
              "itemName": "无抗真空管(干管)",
              "itemType": "一次性材料",
              "itemUnit": "支",
              "itemTotalFee": "0.85",
              "itemPrice": "0.85"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "总二氧化碳(TCO2)测定-酶促动力学法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "9.1",
              "itemPrice": "9.1"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "尿素测定-酶促动力学法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "9.1",
              "itemPrice": "9.1"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "肌酐测定-酶促动力学法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "4.6",
              "itemPrice": "4.6"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "血清胱抑素(Cystatin C)测定",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "44.7",
              "itemPrice": "44.7"
            },
            {
              "itemSpec": "0",
              "itemNumber": "1",
              "itemName": "视黄醇结合蛋白测定-透射比浊法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "10.7",
              "itemPrice": "10.7"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "钾测定-火焰分光光度法或离子选择电极法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "4.6",
              "itemPrice": "4.6"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "钠测定-火焰分光光度法或离子选择电极法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "4.6",
              "itemPrice": "4.6"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "氯测定-离子选择电极法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "4.6",
              "itemPrice": "4.6"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "钙测定-比色法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "4.6",
              "itemPrice": "4.6"
            },
            {
              "itemSpec": "/支",
              "itemNumber": "1",
              "itemName": "EDTK2真空管",
              "itemType": "一次性材料",
              "itemUnit": "支",
              "itemTotalFee": "0.91",
              "itemPrice": "0.91"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "葡萄糖6－磷酸脱氢酶活性检测",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "10.7",
              "itemPrice": "10.7"
            },
            {
              "itemSpec": "0",
              "itemNumber": "1",
              "itemName": "葡萄糖测定-各种酶法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "5.5",
              "itemPrice": "5.5"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "粪便常规",
              "itemType": "检验费",
              "itemUnit": "次",
              "itemTotalFee": "1.8",
              "itemPrice": "1.8"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "隐血试验-免疫法",
              "itemType": "检验费",
              "itemUnit": "项",
              "itemTotalFee": "15.2",
              "itemPrice": "15.2"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "粪寄生虫镜检",
              "itemType": "检验费",
              "itemUnit": "次",
              "itemTotalFee": "1.8",
              "itemPrice": "1.8"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "A级三人以上病房床位费",
              "itemType": "床位费",
              "itemUnit": "床/日",
              "itemTotalFee": "54.3",
              "itemPrice": "54.3"
            },
            {
              "itemSpec": "",
              "itemNumber": "1",
              "itemName": "住院诊查费",
              "itemType": "诊查费",
              "itemUnit": "日",
              "itemTotalFee": "13",
              "itemPrice": "13"
            }

      ],
      loading: false,
      hospitalNum: '',
      patName: '',
      timeSlot: '',
      beginDate: '',
      endDate: '',
      dept: '',
      bedNo: '',
      inDate: '',
      totalFee: '',
      query: {},
      printData: {}
    }
  },
  created () {
    this.query = this.$route.query
    // this.getHospitalizationFeesList()
  },
  methods: {

    // 住院清单
    async getHospitalizationFeesList () {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const { data: res } = await jsonPost(
        "his/getHospitalizationFeesList",
        {
          admissionNum: this.query.admissionNum,
          beginDate: this.query.beginDate,
          endDate: this.query.beginDate,
        }
      );
      if (res.code === 0) {
        this.tableData = res.data.item
        this.printData = res.data
        loading.close()
      } else {
        loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    // 查询住院清单
    async print () {
      // const loading = this.$loading({
      //   lock: true,
      //   text: '加载中...',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      // this.printData.住院号 = this.query.admissionNum
      // this.printData.名字 = this.query.patName
      // this.printData.时间 = this.query.beginDate
      // const { data: res } = await jsonPost(
      //   "/hospitalFilePath",
      //   this.printData
      // );
      // if (res.code == '200') {
      //   loading.close()
      //   this.hospitalfile(res.filePath)
      // } else {
      //   loading.close()
      //   this.$message({
      //     message: "打印服务出错，请联系管理员",
      //     type: 'error'
      //   })
      // }
      let _this = this
      this.printData.住院号 = this.query.admissionNum
      this.printData.名字 = this.query.patName
      this.printData.时间 = this.query.beginDate
      Promise.all([_this.$api.hospitalFilePath(this.printData)]).then((data) => {
        if (data[0].code == '200') {
          this.hospitalfile(data[0].filePath)

        } else {
          this.$message({
            message: "打印服务出错，请联系管理员",
            type: 'error'
          })
        }
      })

    },
    // 打印住院清单
    async hospitalfile (filePath) {
      let _this = this
      console.log("filePath", filePath);
      for (let i = 0; i < filePath.length; i++) {
        const params = {
          filePath: encodeURI(filePath[i])
        }
        Promise.all([_this.$api.hospitalfile(params)]).then((data) => {

        })
      }

    },
    getNum () {
    },
    toggleSelection () {

    },
    tableRowClassName ({ row, rowIndex }) {
      row.row_index = rowIndex;
    },
    home () {
      this.$router.push('/home')
    },
    back () {
      this.$router.push({
        path: '/',
        query: {
          patName: this.query.patName,
          admissionNum: this.query.admissionNum,
          isBack: 1
        }
      })
    },
    recharge () {
      this.show = true
      // if (this.$refs.multipleTable.selection.length === 0) {
      //   alert('请选择住院记录项')
      // } else {
      //   this.show = true
      // }
    },
    inputNum (num) {
      this.value += num
    },
    confirm () {
      const that = this
      if (that.value === '') {
        this.$message({
          message: '金额不能为空',
          type: 'success'
        })
      } else if (that.value === 0 || that.value === '0') {
        this.$message({
          message: '金额不能为零',
          type: 'success'
        })
      } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(that.value)) {
        this.$message({
          message: '输入金额有误，请重新输入',
          type: 'success'
        })
      } else {
        that.$router.push({
          path: '/mode',
          query: {
            name: that.name,
            patName: that.tableData[0].patName,
            regFee: that.value,
            admissionNum: this.hospitalNum
          }
        })
      }
    },
    del () {
      this.value = this.value.substr(0, this.value.length - 1)
    },
    clear () {
      this.value = ''
    },
    cancel () {
      this.show = false
      this.value = ''
    },

  }
}
</script>
<style lang="less" scoped>
.input {
  width: 45%;
  height: 100%;
}

/deep/.el-input__inner {
  border-color: #3b77d8 !important;
  margin-left: 15px;
  border: #3172ff 2px solid !important;
  border-radius: 8px;
  height: 100%;
  font-size: 46px !important;
  color: #000000 !important;
}

.bgc {
  background-color: red;
}

.inpatient_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inpatient_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.inpatient_container .popup {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.inpatient_container .popup h1 {
  text-align: center;
  color: red;
  letter-spacing: 10px;
  margin: 10px 0;
  font-size: 30px;
}

.inpatient_container .popup p {
  margin-top: 20px;
  color: red;
  font-size: 18px;
}

.inpatient_container .popup .btn {
  margin-top: 30px;
}

.inpatient_container .popup .btn .el-button {
  font-size: 20px !important;
}

.inpatient_container .popup .ipt {
  border: 2px solid #000;
  padding-left: 20px;
  font-size: 40px;
  width: 500px;
  height: 60px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 60px;
}

.inpatient_container .popup .keyboard {
  margin: 0 auto;
  width: 500px;
  margin-top: 20px;
}

.inpatient_container .popup .keyboard .num {
  width: 350px;
  float: left;
}

.inpatient_container .popup .keyboard .num .el-button {
  margin: 0;
  float: left;
  width: 60px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .keyboard .funtion {
  width: 100px;
  float: left;
}

.inpatient_container .popup .keyboard .funtion .el-button {
  margin: 0;
  width: 130px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.inpatient_container .popup .btn .el-button {
  font-size: 30px !important;
  margin: 0 40px;
}

.inpatient_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.inpatient_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

// .back {
//   position: absolute;
//   bottom: 80px;
//   right: 150px;
//   font-size: 26px !important;
// }
.print {
  width: 40%;
  height: 80px;
  margin-left: 60px;
  margin-bottom: 10px;

  // position: fixed;
  // left: 0px;
  // bottom: 240px;
  font-size: 40px !important;
}

.detailBack {
  width: 50%;
  margin-left: 60px;
}

.search {
  width: 220px;
  height: 120px;
  right: 30px;
  font-size: 26px !important;
}

.info {
  margin-top: 10%;
  text-align: center;
}

.inpatient_main {
  margin: 0 auto;
  width: 90%;
  // height: 70%;
}

.line {
  height: 10%;
}

.tip {
  text-align: center;
}

.inpatient_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.inpatient_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.inpatient_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.recharge {
  height: 80px;
  position: absolute;
  bottom: 320px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px !important;
}

.file_row {
  height: 80px;
  display: flex;
  justify-content: space-around;
  padding: 0 45px;
}

.file_row .cdrNum {
  width: 50%;
  font-size: 60px;
}

.file_row span {
  font-size: 60px;
  line-height: 80px;
}
</style>
